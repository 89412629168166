import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import LeadText from '../../components/LeadText/LeadText';
import StickySocialLinks from '../../components/StickySocialLinks/StickySocialLinks';
import Tabs from '../../components/Tabs/Tabs';
import Content from '../../components/Content/Content';
import Nested404 from '../../components/Nested404/Nested404';

import ServicesPageData from '../../data/views/our-services.json';

import classes from './Services.module.css';

const Services = (props) => {
    const defaultResourceName = ServicesPageData.services.tabs.items.find((item) => item.defaultResource === true).resourceName;

    return (
        <StickySocialLinks>
            <LeadText
                headingText={ServicesPageData.leadText.headline}
                paragraphText={ServicesPageData.leadText.paragraph}
            />
            <div className={classes.Content}>
                <div
                    id='services-tabs'
                    className={classes.TabsContainer}
                >
                    <Tabs
                        config={ServicesPageData.services.tabs}
                    >
                        <Routes>
                            {ServicesPageData.services.tabs.items.map((item, itemIdx) => {
                                return (
                                    <Route
                                        key={itemIdx}
                                        path={`/${item.resourceName}`}
                                        element={
                                            <Content
                                                contents={item.data}
                                                contentName={item.name}
                                                contentDescription={item.description}
                                            />
                                        }
                                    />
                                )
                            })}
                            <Route
                                path='/'
                                element={<Navigate replace to={`/services/${defaultResourceName}`} />}
                            />
                            <Route path="*" element={<Nested404 notFoundImgPath={`${process.env.PUBLIC_URL}/other/404.jpg`} notFoundImgAlt='404 not found error' />} />
                        </Routes>
                    </Tabs>
                </div>
                <img
                    className={classes.DesktopImage}
                    src={`${process.env.PUBLIC_URL}${ServicesPageData.sideImage.imagePath}`}
                    alt={ServicesPageData.sideImage.imageAlt}
                />
            </div>
        </StickySocialLinks>
    )
}

export default Services;
