import React from 'react';
import { NavLink } from 'react-router-dom';

import commonClasses from '../../styles/common.module.css';
import classes from './StickySocialLinks.module.css';

const StickySocialLinks = (props) => {

    return (
        <div className={classes.StickySocialLinks}>
            <main className={classes.ViewContent}>
                {props.children}
            </main>
            <ul className={classes.StickySocialLinkList}>
                <li>
                    <a
                        className={[commonClasses.Clickable, classes.SocialLink].join(' ')}
                        style={ {backgroundImage: `url(${process.env.PUBLIC_URL + '/logos/facebook.png'})`}}
                        href='https://www.facebook.com/AZRestandRec/'
                        target='_blank'
                        aria-label='Facebook Link.  Use this link to open our Facebook page in a separate browser tab.'
                    >
                    </a>
                </li>
                <li>
                    <a
                        className={[commonClasses.Clickable, classes.SocialLink].join(' ')}
                        style={ {backgroundImage: `url(${process.env.PUBLIC_URL + '/logos/instagram.png'})`}}
                        href='https://www.instagram.com/page.s.morris/'
                        target='_blank'
                        aria-label='Instagram Link.  Use this link to open our Instagram page in a separate browser tab.'
                    >
                    </a>
                </li>
                <li>
                    <NavLink
                        className={[commonClasses.Clickable, classes.SocialLink].join(' ')}
                        style={ {backgroundImage: `url(${process.env.PUBLIC_URL + '/logos/email.png'})`} }
                        to={ {hash: '#contact-us'} }
                        state={ {focusTargetId: '#name'} }
                        // aria-current={null}
                        aria-label='Contact Us Link.  Use this link to navigate to our contact us form.'
                    />
                </li>
            </ul>
        </div>
    );
}

export default StickySocialLinks;
