import React, { useState, useEffect, useRef } from 'react';

import ImageSlide from './ImageSlide/ImageSlide';

import commonClasses from '../../styles/common.module.css';
import classes from './ImageCarousel.module.css';

const ImageCarousel = ({
    slideData
}) => {
    const listingDetailsLink1Ref = useRef();
    const listingDetailsLink2Ref = useRef();

    const [slideStage, setSlideStage] = useState({
        currentSlideIndex: 0,
        previousSlideIndex: 0,
        activeSlidePanel: 'panel1'
    });
    const [contentHasMouseover, setContentHasMouseover] = useState(false);
    const [contentHasFocus, setContentHasFocus] = useState(false);

    useEffect(() => {
        const focusedElement = document.activeElement;
        if (focusedElement === listingDetailsLink1Ref.current) {
            listingDetailsLink2Ref.current.focus();
        }
        else if (focusedElement === listingDetailsLink2Ref.current) {
            listingDetailsLink1Ref.current.focus();
        }

    }, [slideStage])

    const currentSlide = slideData[slideStage.currentSlideIndex];
    const previousSlide = slideData[slideStage.previousSlideIndex];

    const transitionSlides = () => {
        setSlideStage( ({
            currentSlideIndex, previousSlideIndex, activeSlidePanel
        }) => {
            const newSlideIndex = (currentSlideIndex === slideData.length - 1) ? 0 : currentSlideIndex + 1;
            const oldSlideIndex = (newSlideIndex === 0) ? slideData.length - 1 : newSlideIndex - 1;
            const activePanel = (activeSlidePanel === 'panel1') ? 'panel2' : 'panel1';

            return {
                currentSlideIndex: newSlideIndex,
                previousSlideIndex: oldSlideIndex,
                activeSlidePanel: activePanel
            }
        })
    }

    const goToSlide = (slideIndex) => {
        if (slideIndex !== slideStage.currentSlideIndex) {
            setSlideStage( ({
                currentSlideIndex, previousSlideIndex, activeSlidePanel
                }) => {
                    const oldSlideIndex = currentSlideIndex;
                    const newSlideIndex = slideIndex;
                    const activePanel = (activeSlidePanel === 'panel1') ? 'panel2' : 'panel1';

                    return {
                        currentSlideIndex: newSlideIndex,
                        previousSlideIndex: oldSlideIndex,
                        activeSlidePanel: activePanel
                    }
            });
        }
    }

    useEffect(() => {
        let intervalId = null;
        if (!contentHasMouseover && !contentHasFocus) {
            intervalId = setInterval(() => {transitionSlides()}, 8000);
        }
        return () => {
            clearInterval(intervalId);
            intervalId = null;
        }
    });

    return (
        <div
            id='listings-carousel'
            className={classes.ImageCarousel}
            aria-label='Our top listings'
            onMouseEnter={() => setContentHasMouseover(true)}
            onMouseLeave={() => setContentHasMouseover(false)}
        >
            <ImageSlide
                ref={listingDetailsLink1Ref}
                slideDetails={(slideStage.activeSlidePanel === 'panel1') ? currentSlide : previousSlide}
                isActive={(slideStage.activeSlidePanel === 'panel1')}
                linkFocused={() => setContentHasFocus(true)}
                linkBlurred={() => setContentHasFocus(false)}
            />
            <ImageSlide
                ref={listingDetailsLink2Ref}
                slideDetails={(slideStage.activeSlidePanel === 'panel2') ? currentSlide : previousSlide}
                isActive={(slideStage.activeSlidePanel === 'panel2')}
                linkFocused={() => setContentHasFocus(true)}
                linkBlurred={() => setContentHasFocus(false)}
            />
            <div className={classes.ButtonContainer}>
                {
                    slideData.map((slide, slideIndex) => {
                        return (
                            <button
                                key={slideIndex}
                                className={
                                    [
                                        commonClasses.Clickable,
                                        classes.CarouselButton,
                                        (slideStage.currentSlideIndex === slideIndex) ? commonClasses.active : null,
                                        (slideStage.currentSlideIndex === slideIndex) ? classes.active : null].join(' ')}
                                onClick={() => goToSlide(slideIndex)}
                                onFocus={() => setContentHasFocus(true)}
                                onBlur={() => setContentHasFocus(false)}
                                aria-label={`Press Enter key or Space bar to show listing number ${slideIndex + 1} of ${slideData.length} listings`}
                            >
                            </button>
                        )
                    })
                }
            </div>
        </div>
    );
}

export default ImageCarousel;
