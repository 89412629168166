import React from 'react';

import classes from './LeadText.module.css';

const LeadText = ({
    headingText,
    paragraphText
}) => {
    return (
        <section className={classes.LeadText}>
            <h1>{headingText}</h1>
            <p>{paragraphText}</p>
        </section>
    )
}

export default LeadText;
