import React, { useState, useEffect } from 'react';

import useWindowWidth from '../../hooks/useWindowWidth';

import classes from './Hero.module.css';

const Hero = ( {
    imageDesktop,
    imageTablet,
    imageCell,
    imageAlt,
    headlinePart1,
    headlinePart2,
    spokenHeadline,
    headlinePos,
    headlineColor,
    headlineShadowColor
} ) => {
    const windowDimensions = useWindowWidth();
    const [heroImageSrc, setHeroImageSrc] = useState();

    useEffect(() => {
        if (windowDimensions.width < 900) {
            setHeroImageSrc(imageCell);
        }
        else if (windowDimensions.width < 1200) {
            setHeroImageSrc(imageTablet);
        }
        else {
            setHeroImageSrc(imageDesktop);
        }
    }, [windowDimensions.width]);

    const defaultHeadlineColor = '#F4A800';
    const defaultHeadlineShadowColor = '#000000';

    const textShadowCss =
     `-1px 0 1px ${headlineShadowColor},
     0 1px 1px ${headlineShadowColor},
     1px 0 1px ${headlineShadowColor},
     0 -1px 1px ${headlineShadowColor}`;

    const defaultTextShadowCss =
    `-1px 0 1px ${defaultHeadlineShadowColor},
    0 1px 1px ${defaultHeadlineShadowColor},
    1px 0 1px ${defaultHeadlineShadowColor},
    0 -1px 1px ${defaultHeadlineShadowColor}`;

    let overlayClasses = [classes.Overlay];

    switch (headlinePos) {
        case 'topLeft':
            overlayClasses.push(classes.TopLeftPos);
            break;
        case 'bottomLeft':
            overlayClasses.push(classes.BottomLeftPos);
            break;
        case 'topRight':
            overlayClasses.push(classes.TopRightPos);
            break;
        case 'bottomRight':
            overlayClasses.push(classes.BottomRightPos);
            break;
        default:
            overlayClasses.push(classes.BottomLeftPos);
    }

    return (
        <div className={classes.Hero}>
            <div className={overlayClasses.join(' ')}>
                <span
                    className={classes.Span}
                    style={{ color: `${headlineColor ? headlineColor : defaultHeadlineColor}`,
                            textShadow: `${headlineShadowColor ? textShadowCss : defaultTextShadowCss}` }}
                >
                    {headlinePart1}
                </span>
                <span
                    className={classes.Span}
                    style={{ color: `${headlineColor ? headlineColor : defaultHeadlineColor}`,
                            textShadow: `${headlineShadowColor ? textShadowCss : defaultTextShadowCss}` }}
                >
                    {headlinePart2}
                </span>
            </div>
            <img
                className={classes.Image}
                src={heroImageSrc}
                alt={imageAlt}
            />
        </div>

    )
}

export default Hero;
