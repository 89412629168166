import React from 'react';
import { NavLink } from 'react-router-dom';

import classes from './Content.module.css';

const Content = ({
    contentName,
    contentDescription,
    contents
}) => {
    return (
        <div className={classes.Content}>
            {
                Object.keys(contents).map((contentsKey, contentIndex) => {
                    const contentsItem = contents[contentsKey];
                    if (contentsItem.type === 'unorderedList') {
                        return (
                            <ul
                                key={contentIndex}
                                className={classes.ContentList}
                            >
                                {
                                    contentsItem.items.map((item, index) => {
                                        return (
                                            <li key={index}>
                                                <b>{item.summary}</b>
                                                <span>{item.description}</span>
                                            </li>
                                        );
                                    })
                                }
                            </ul>
                        )
                    }
                    else if (contentsItem.type === 'heading2') {
                        return (
                            <h2 key={contentIndex}>{contentsItem.text}</h2>

                        )
                    }
                })
            }
            <b className={classes.ContactUs}><i>{`Have questions about our ${contentDescription}?`}&nbsp;&nbsp;</i></b>
            <b className={classes.ContactUs}>
                <i>
                    <NavLink
                        to={{hash: '#contact-us'}}
                        state={ {focusTargetId: '#name'} }
                    >
                        Contact us
                    </NavLink>
                    &nbsp;and we will be in touch with you shortly!
                </i>
            </b>
        </div>
    )
}

export default Content;
