import React, { forwardRef } from 'react';

import classes from './DrawerToggle.module.css';

const DrawerToggle = forwardRef(({
    toggledOn,
    clicked
}, drawerToggleRef) => {
    const drawerToggleClasses = [classes.DrawerToggle];
    if (toggledOn) {
        drawerToggleClasses.push(classes.Active);
    }
    else {
        drawerToggleClasses.push(classes.Inactive);
    }

    return (
        <button
            className={drawerToggleClasses.join(' ')}
            ref={drawerToggleRef}
            tabIndex='0'
            onClick={clicked}
            aria-label={'site navigation drawer toggle'}
        >
            <span></span>
            <span></span>
            <span></span>
        </button>
    );
});

export default DrawerToggle;
