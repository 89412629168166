import React from 'react';

import classes from './AbsolutePositionModal.module.css'

const AbsolutePositionModal = ( props ) => {
    return (
        <div className={classes.AbsolutePositionModal}>
            <div className={classes.Backdrop} onClick={props.clicked}></div>
            <div className={classes.Content} aria-label='status of sending message'>
                {props.children}
            </div>
        </div>
    )
}

export default AbsolutePositionModal;
