import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import smoothscroll from 'smoothscroll-polyfill';
import focusoptions from 'focus-options-polyfill'; // eslint-disable-line no-unused-vars
import reportWebVitals from './reportWebVitals';

const app = (
    <React.StrictMode>
      <App />
    </React.StrictMode>
);

smoothscroll.polyfill();
ReactDOM.render(app, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
