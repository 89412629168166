import React, { forwardRef } from 'react';

import NavigationItems from '../UI/Navigation/NavigationItems/NavigationItems';
import DrawerToggle from '../UI/Navigation/DrawerToggle/DrawerToggle';
import Logo from '../UI/Logo/Logo';

import classes from './Header.module.css';

const Header = forwardRef(({
    routes,
    drawerToggleClicked,
    logoClicked,
    sideDrawerOpen
}, drawerToggleRef) => {
    return (
        <header
            className={classes.Header}
            id='site-header'
        >
            <Logo
                imgPath={`${process.env.PUBLIC_URL}/logos/logo-short.png`}
                height='70%'
                padding='0'
                clicked={() => logoClicked()}
            />
            <span className={classes.Title}>AZ R&R</span>
            <nav className={classes.DesktopOnly}>
                <NavigationItems items={routes} />
            </nav>
            <DrawerToggle
                ref={drawerToggleRef}
                clicked={drawerToggleClicked}
                toggledOn={sideDrawerOpen}
            />
        </header>
    )
});

export default Header;
