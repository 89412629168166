import React from 'react';

import Headshot from '../../components/Headshot/Headshot';

import classes from './TeamMember.module.css';

const TeamMember = ({memberDetails}) => {
    return (
        <div className={classes.TeamMember}>
            <Headshot
                name={`${memberDetails.firstName} ${memberDetails.lastName}`}
                title={memberDetails.title}
                imagePath={memberDetails.headshotImagePath}
                imageAlt={memberDetails.headshotImageAlt}
            />
            <div>
                {memberDetails.bio.map((bioBlock, index) => {
                    return (
                        <p key={index}>
                            {bioBlock}
                        </p>
                    )
                })}
            </div>
        </div>
    )
}

export default TeamMember;
