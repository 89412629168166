import React from 'react';
import { useLocation } from 'react-router-dom';

import NavigationItem from './NavigationItem/NavigationItem';
import classes from './NavigationItems.module.css';

const NavigationItems = ({
    items,
    show,
    navItemClicked
}) => {
    const {pathname} = useLocation();
    const itemsLength = items.length;
    let itemNumber = 0;

    return (
        <ul className={classes.NavigationItems}>
            {items.map((route, index) => {
                if (route.path !== '/') {
                    itemNumber++;
                    return (
                        <NavigationItem
                            key={index}
                            link={route.path}
                            visible={show}
                            clicked={navItemClicked}
                            itemOrder={itemNumber}
                            reverseItemOrder={Math.abs(itemNumber - itemsLength)}
                            currentRoute={(pathname.includes(route.path))}
                            readerVisible={show}
                        >
                            {route.name}
                        </NavigationItem>
                    );
                }
                else {
                    return null;
                }
            })}
        </ul>
    )
}

export default NavigationItems;
