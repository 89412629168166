import React from 'react';

import { Link, useLocation } from 'react-router-dom';

import classes from './Logo.module.css';

function Logo({
    imgPath,
    height,
    padding,
    clicked
}) {
    const {pathname} = useLocation();

    return (
        <div
            className={classes.Logo}
            style={{height: height, padding: padding}}
            onClick={clicked}
        >
            <Link
                to='/'
                aria-current={(pathname === '/') ? 'page' : null}
                aria-label={'Link.  AZ Rest and Recreation logo - use this link to go to our home page'}
            >
                <img
                    src={imgPath}
                    alt='AZ Rest and Recreation logo'
                />
            </Link>
        </div>
    )
}

export default Logo;
