import React from 'react';
import { NavLink } from 'react-router-dom';

import classes from './NavigationItem.module.css';

const NavigationItem = ({
    link,
    visible = true,
    clicked,
    itemOrder,
    reverseItemOrder,
    currentRoute,
    children
}) => {
    const showDelayIncrementInMillis = 100;
    const hideDelayIncrementInMillis = 50;
    let delayInMillis;
    // subtract 1 so that the first link in a list does not have a delay
    if (visible) {
        delayInMillis = `${ 250 + (itemOrder - 1) * showDelayIncrementInMillis}ms`;
        // console.log('delayInMillis ', delayInMillis);
    }
    else {
        delayInMillis = `${ (reverseItemOrder - 1) * hideDelayIncrementInMillis}ms`;
        // console.log('reverse delayInMillis ', delayInMillis);
    }

    return (
        <li
            style={ {transitionDelay: delayInMillis} }
            className={[
                    classes.NavigationItem,
                    (visible ? classes.Reveal : classes.Hide)
                ].join(' ')}>
            <NavLink
                to={link}
                onClick={clicked}
                className={navData => navData.isActive ? classes.active : ''}
                aria-current={currentRoute ? 'page' : null}
                tabIndex={visible ? null : '-1'}
            >
                {children}
            </NavLink>
        </li>
    );
}

export default NavigationItem;
