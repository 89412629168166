import React from 'react';
import { NavLink } from 'react-router-dom';

import LeadText from '../../components/LeadText/LeadText';
import StickySocialLinks from '../../components/StickySocialLinks/StickySocialLinks';

import classes from './NotFound.module.css';

const NotFound = ( props ) => {
    return (
        <StickySocialLinks>
            <div className={classes.NotFound}>
                <div className={classes.ImageWrapper}>
                    <img
                        src={`${process.env.PUBLIC_URL}/other/404.jpg`}
                        alt={'404 Error'}
                    />
                    <div className={classes.ImageSmoothEdge}></div>
                </div>
                <LeadText
                    headingText='404 - Page Not Found'
                    paragraphText={'We\'re sorry....We love adventurous spirits, but we cannot find the page you were hoping for....'}
                />
                <div className={classes.HelpfulLinksSection}>
                    <b>Use the following to help you quickly get to your destination:</b>
                    <ul className={classes.HelpfulLinks}>
                        <li>Browse through <NavLink to="/listings">Our Listings</NavLink> to see the beautiful properties we manage.</li>
                        <li>Check out the <NavLink to="/about-us">About Us</NavLink> page to get to know our awesome team better.</li>
                        <li>Learn about the services we offer to guests as well as home owners on <NavLink to="/services">Our Services</NavLink> page.</li>
                        <li>To get the big picture, check out our <NavLink to="/">Home</NavLink> page.</li>
                        <li>For personalized assistance from one of our excellent team members, send us a note using the contact form below.</li>
                    </ul>
                </div>
            </div>
        </StickySocialLinks>
    );
}

export default NotFound;
