import React from 'react';
import { NavLink } from 'react-router-dom';

import classes from './TabItemLink.module.css';

const TabItemLink = (
    {
        resourcePath,
        screenReaderText,
        children
    }) => {

    return (
        <NavLink
            to={resourcePath}
            state={ {preventScroll: true} }
            className={navData => {return navData.isActive ? [classes.TabItemLink, classes.active].join(' ') : classes.TabItemLink}}
            aria-label={screenReaderText}
        >
            {children}
        </NavLink>
    );
};

export default TabItemLink;
