import React from 'react';
import { NavLink } from 'react-router-dom';

import StickySocialLinks from '../../components/StickySocialLinks/StickySocialLinks';
import ImageCarousel from '../../components/ImageCarousel/ImageCarousel';
import LeadText from '../../components/LeadText/LeadText';
import Listing from '../../components/Listing/Listing';

import ListingPageData from '../../data/views/our-listings.json';
import ListingData from '../../data/listings.json';

import classes from './Listings.module.css';

const Listings = (props) => {

    const allListings = [];
    const CAROUSEL_MAX_LISTINGS = 4;
    let listingId = 1;
    const featuredListing = ListingData.featuredListing;
    featuredListing.id = `listing-${listingId}`;
    allListings.push(ListingData.featuredListing);

    Object.keys(ListingData.otherListings).forEach((listingKey) => {
        listingId++;
        const listing = ListingData.otherListings[listingKey];
        listing.id = `listing-${listingId}`;
        allListings.push(listing);
    });

    const carouselData = [];
    allListings.slice(0, CAROUSEL_MAX_LISTINGS).forEach((listing) => {
        const listingImage = listing.selectedImagesPaths.premiereImage;
        carouselData.push(
            {
                id: listing.id,
                captionPart1: `${listing.bedrooms} Bedroom ${listing.dwellingType}`,
                captionPart2: listing.location,
                image: listingImage
            }
        )
    });

    return (
        <>
            <ImageCarousel slideData={carouselData} />
            <StickySocialLinks>
                <LeadText
                    headingText={ListingPageData.leadText.headline}
                    paragraphText={ListingPageData.leadText.paragraph}
                />
                <i className={classes.ContactUs}>{`Have questions about our properties?`}&nbsp;&nbsp;
                    <NavLink
                        to={{hash: '#contact-us'}}
                        state={ {focusTargetId: '#name'} }
                    >
                        Contact us
                    </NavLink>
                    &nbsp;and we will be in touch with you shortly!
                </i>
                <ul
                    id='all-listings'
                    className={classes.Listings}
                >
                    {
                        allListings.map((listingDetails, index) => {
                            return (
                                <li
                                    key={index}
                                    id={listingDetails.id}
                                >
                                    <Listing listingData={listingDetails} />
                                    {
                                        (index === allListings.length - 1)
                                            ? null
                                            : <hr key={`hr${index}`} className={classes.Divider} />
                                    }
                                </li>
                            );
                        })
                    }
                </ul>
            </StickySocialLinks>
        </>
    )
}

export default Listings;
