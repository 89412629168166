import React, { useState } from 'react';

import classes from './ImageSlider.module.css';

const ImageSlider = ({
    imagePaths
}) => {
    const [imageIndex, setImageIndex] = useState(0);

    const imageCount = imagePaths.length;

    const incrementImageIndex = () => {
        setImageIndex( (currentImageIndex) =>
            (currentImageIndex === imageCount - 1)
                ? 0
                : currentImageIndex + 1
        )
    }

    const decrementImageIndex = () => {
        setImageIndex( (currentImageIndex) =>
            (currentImageIndex === 0)
                ? imageCount - 1
                : currentImageIndex - 1
        )
    }

    const currentImage = imagePaths[imageIndex];
    const webpPath = `${process.env.PUBLIC_URL}${currentImage.webp}`

    return (
        <div className={classes.ImageSlider}>
            <button
                className={[classes.ChevronButton, classes.Left].join(' ')}
                onClick={() => decrementImageIndex()}
                aria-label='Previous photo button.  Use this button to show the previous photo for this listing.'
            >
                <img
                    src={`${process.env.PUBLIC_URL}/icons/chevron-solid-left-circle-inactive.png`}
                    alt='left arrow'
                />
                <img
                    src={`${process.env.PUBLIC_URL}/icons/chevron-solid-left-circle-active.png`}
                    alt='left arrow'
                />
            </button>
            <picture className={classes.ImageSliderImg}>
                <source
                    srcSet={`${process.env.PUBLIC_URL}${currentImage.webp}`}
                    type="image/webp"
                    alt={currentImage.alt}
                />
                <source
                    srcSet={`${process.env.PUBLIC_URL}${currentImage.jpg}`}
                    type="image/jpeg"
                    alt={currentImage.alt}
                />
                <img
                    src={`${process.env.PUBLIC_URL}${currentImage.jpg}`}
                    alt={currentImage.alt}
                    // alt='this is a very very long alt text that can be longer than the image width'
                />
            </picture>
            <button
                className={[classes.ChevronButton, classes.Right].join(' ')}
                onClick={() => incrementImageIndex()}
                aria-label='Next photo button.  Use this button to show the next photo for this listing.'
            >
                <img
                    src={`${process.env.PUBLIC_URL}/icons/chevron-solid-right-circle-inactive.png`}
                    alt='right arrow'
                />
                <img
                    src={`${process.env.PUBLIC_URL}/icons/chevron-solid-right-circle-active.png`}
                    alt='right arrow'
                />
            </button>
        </div>
    )
}

export default ImageSlider;
