import React from 'react';

import TabItemLink from '../UI/TabItemLink/TabItemLink';

import classes from './Tabs.module.css';

const Tabs = ({
    config,
    children
}) => {
    return (
        <div
            id={config.name}
            className={classes.Tabs}
        >
            <div className={classes.TabLinks}>
                {
                    config.items.map((item, itemIndex) => {
                        return (
                            <TabItemLink
                                key={itemIndex}
                                resourcePath={item.resourceName}
                                screenReaderText={item.screenReaderText}
                            >
                                {item.name}
                            </TabItemLink>
                        )
                    })
                }
            </div>
            <div className={classes.TabItem}>
                {children}
            </div>
        </div>
    );
};

export default Tabs;
