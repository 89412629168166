import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Layout from './components/Layout/Layout';
import Home from './views/Home/Home';
import Services from './views/Services/Services';
import Listings from './views/Listings/Listings';
import AboutUs from './views/AboutUs/AboutUs';
import SiteMap from './views/SiteMap/SiteMap';
import PrivacyPolicy from './views/PrivacyPolicy/PrivacyPolicy';
import TermsOfService from './views/TermsOfService/TermsOfService';
import NotFound from './views/NotFound/NotFound';

function App() {
    return (
        <BrowserRouter>
            <Layout>
                <Routes>
                    <Route path="/services/*" element={<Services />} />
                    <Route path="/listings" element={<Listings />} />
                    <Route path="/about-us" element={<AboutUs />} />
                    <Route path="/sitemap" element={<SiteMap />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/terms-of-service" element={<TermsOfService />} />
                    <Route index element={<Home />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Layout>
        </BrowserRouter>
    );
}

export default App;