import { useState } from 'react';

const useDeclarativeScrollAndFocus = () => {
    const handleScrollFinish = (focusTarget, callback = () => {}) => {
        let scrollTimeout;
        const awaitScrollStop = (event) => {
            window.clearTimeout(scrollTimeout);
            scrollTimeout = setTimeout(function() {
                scrollTimeout = null;
                window.clearTimeout(scrollTimeout);
                window.removeEventListener('scroll', awaitScrollStop);
                if (focusTarget) {
                    focusOnTargetRef(focusTarget, callback);
                }
                else {
                    callback();
                }
            }, 400);
        }
        window.addEventListener('scroll', awaitScrollStop, false);
    }

    const awaitScrollEnd = (callback = () => {}) => {
        let scrollTimeout;
        const awaitScrollStop = (event) => {
            window.clearTimeout(scrollTimeout);
            scrollTimeout = setTimeout(function() {
                scrollTimeout = null;
                window.clearTimeout(scrollTimeout);
                window.removeEventListener('scroll', awaitScrollStop);
                callback();
            }, 400);
        }
        window.addEventListener('scroll', awaitScrollStop, false);
    }

    const scrollAndFocusByElementId = (elementId, topBuffer = 0) => {
        if (!elementId) {
            return;
        }
        const element = document.querySelector(elementId);
        if (!element) {
            return;
        }
        let buffer;
        if (topBuffer === 0) {
            const style = window.getComputedStyle ? getComputedStyle(element, null) : element.currentStyle;
            buffer = parseInt(style.marginTop) || 0;
        }
        else {
            buffer = topBuffer;
        }

        const desiredPixelPos = element.offsetTop - buffer;
        awaitScrollEnd(() => {
            element.setAttribute('tabindex', '0');
            element.focus({ preventScroll: true });
            element.setAttribute('tabindex', '-1');
        });
        window.scrollTo({
            top: desiredPixelPos,
            behavior: 'smooth'
        });
    }

    const focusOnTargetRef = (target, callback = () => {}) => {
        if (target) {
            target.current.setAttribute('tabindex', '0');
            target.current.focus({ preventScroll: true });
            target.current.setAttribute('tabindex', '-1');
        }
        callback();
    }

    const scrollAndFocusByRef = (scrollTarget, focusTarget, callback = () => {}) => {
        if (scrollTarget) {
            const scrollTargetOffset = scrollTarget.current.offsetTop - window.pageYOffset;
            if (scrollTargetOffset !== 0) {
                if (focusTarget) {
                    handleScrollFinish(focusTarget, callback);
                }
                window.scrollTo({
                    top: scrollTarget.current.offsetTop,
                    left: 0,
                    behavior: 'smooth'
                });
            }
        }
        else if (focusTarget) {
            focusOnTargetRef(focusTarget, callback);
        }
        else {
            callback();
        }
    }

    const scrollByElementId = (elementId, topBuffer = 0, callback = () => {}) => {
        if (!elementId) {
            return;
        }
        const element = document.querySelector(elementId);
        if (!element) {
            return;
        }
        let buffer;
        if (topBuffer === 0) {
            const style = window.getComputedStyle ? getComputedStyle(element, null) : element.currentStyle;
            buffer = parseInt(style.marginTop) || 0;
        }
        else {
            buffer = topBuffer;
        }

        const desiredPixelPos = element.offsetTop - buffer;
        const currentYScrollPos = window.pageYOffset || document.documentElement.scrollTop;

        if (desiredPixelPos !== currentYScrollPos) {
            awaitScrollEnd(() => {
                callback();
            });
            window.scrollTo({
                top: desiredPixelPos,
                behavior: 'smooth'
            });
        }
        else {
            callback();
        }
    }

    return { scrollByElementId, scrollAndFocusByElementId, scrollAndFocusByRef, focusOnTargetRef};
};

export default useDeclarativeScrollAndFocus;
