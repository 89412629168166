import React from 'react';
import { NavLink } from 'react-router-dom';

import Logo from '../UI/Logo/Logo';

import TeamMemberData from '../../data/team.json';

import classes from './Footer.module.css';

const Footer = ({
    routes
}) => {
    const currentYearCopyrightStr = `\u00A9 ${new Date().getFullYear()} Arizona Rest and Recreation. All rights reserved.`;

    return (
        <footer className={classes.Footer}>
            <div className={classes.MainFooter}>
                <div className={classes.LogoContainer}>
                    <Logo
                        imgPath={`${process.env.PUBLIC_URL}/logos/logo-full.png`}
                        height='100%'
                        padding="0"
                    />
                </div>
                <div className={classes.PhysicalAddress}>
                    <b>AZ Rest and Recreation</b>
                    <span>5001 N 38th Place</span>
                    <span>Phoenix, AZ&nbsp;&nbsp;85018</span>
                </div>
                <div className={classes.PhoneEmailContacts}>
                    {
                        Object.keys(TeamMemberData.team).map((member, index) => {
                            const teamMember = TeamMemberData.team[member];
                            return (
                                <div
                                    key={index}
                                    className={classes.PhoneEmailContact}
                                >
                                    <b>{teamMember.firstName}&nbsp;{teamMember.lastName},&nbsp;&nbsp;
                                        <i>{teamMember.title}</i>
                                    </b>
                                    <div>
                                        <img
                                            src={`${process.env.PUBLIC_URL}/icons/phone-call-icon.png`}
                                            alt='telephone icon'
                                        />
                                        <a
                                            href={`tel:${teamMember.phoneNumber}`}
                                            aria-label={`call ${teamMember.firstName} ${teamMember.lastName} at ${teamMember.spokenPhone}`}
                                        >
                                            {teamMember.phoneStr}
                                        </a>
                                    </div>
                                    <div>
                                        <img
                                            src={`${process.env.PUBLIC_URL}/icons/send-email-icon.png`}
                                            alt='email icon'
                                        />
                                        <a
                                            href={`mailto:${teamMember.emailAddress}`}
                                            // target= "_blank"
                                            aria-label={`email ${teamMember.firstName} ${teamMember.lastName} at ${teamMember.spokenEmail}`}
                                        >
                                            {teamMember.emailAddress}
                                        </a>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
                <i className={classes.Mission}>
                    We are passionate about hospitality and exceptional guest and owner services!&nbsp;&nbsp;From
                    managing reservations to providing full service, and everything in between, we take great pride
                    in covering your vacation rental property essentials.
                </i>
                <ul className={classes.SiteNav}>
                    {
                        routes.mainRoutes.map( (route, idx) => {
                            if (route.path !== '/') {
                                return (
                                    <li key={idx}>
                                        <NavLink
                                            to={route.path}
                                            className={(navData) => navData.isActive ? [classes.active].join(' ') : "" }
                                        >
                                            {route.name}
                                        </NavLink>
                                    </li>
                                );
                            }
                            else {
                                return null;
                            }
                        }
                    )}
                </ul>
                <ul className={classes.OtherLinks}>
                    {routes.otherRoutes.map( (route, idx) => {
                        return (
                            <li key={idx}>
                                <NavLink
                                    to={route.path}
                                    className={(navData) => navData.isActive ? [classes.active].join(' ') : "" }
                                >
                                    {route.name}
                                </NavLink>
                            </li>
                        );
                    })}
                </ul>
            </div>
            <div className={classes.SubFooter}>
                <span className={classes.OwnerMention}>{currentYearCopyrightStr}</span>
                <span className={classes.WebDesignerMention}>
                    <i>Powered by</i>
                    <img
                        src={`${process.env.PUBLIC_URL}/logos/transparent-magnet-web-designs.png`}
                        alt='Logo for Magnet Web Designs LLC'
                    />
                    <a
                        href='http://www.magnetwebdesigns.com'
                        target= "_blank"
                        rel="noopener noreferrer"
                        
                    >
                        Magnet Web Designs
                    </a>
                </span>
            </div>
        </footer>
    )
}

export default Footer;
