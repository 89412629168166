import React, { forwardRef } from 'react';
import { NavLink } from 'react-router-dom';

import commonClasses from '../../../styles/common.module.css';
import classes from './StyledLink.module.css';

const StyledLink = forwardRef(({
    path,
    hash,
    routeState,
    isFocusable = true,
    focused,
    blurred,
    screenReaderText,
    children
}, linkRef) => {

    const navigationDetails = path
        ? { pathname: path, hash: hash }
        : { hash: hash };

    return (
        <NavLink
            ref={linkRef}
            className={[commonClasses.Clickable, classes.StyledLink, (isFocusable ? null : classes.NotFocusable)].join(' ')}
            to={navigationDetails}
            state={(routeState) ? routeState : null}
            activeclassname={classes.active}
            tabIndex={isFocusable ? '0' : '-1'}
            onFocus={focused}
            onBlur={blurred}
            aria-label={screenReaderText}
        >
            {children}
        </NavLink>
    );
});

export default StyledLink;
