import React, { forwardRef, useEffect, useRef } from 'react';

import NavigationItems from '../UI/Navigation/NavigationItems/NavigationItems';

import classes from './SideDrawer.module.css';

const SideDrawer = forwardRef(({
    routes,
    open,
    close,
    focusToggle
}, drawerToggleRef) => {
    useEffect(() => {
        const links = sideDrawerRef.current.getElementsByTagName('a');
        const lastLink = links[links.length - 1];

        const handleKeyDown = (event) => {
            if ( !event.keyCode || event.keyCode === 27 ) {
                event.preventDefault();
                close();
            }
            const isTabKeyPressed = event.key === 'Tab' || event.keyCode === 9;

            if (!isTabKeyPressed) {
                return;
            }
            else if (event.shiftKey && document.activeElement === drawerToggleRef.current) {
                event.preventDefault();
                lastLink.focus();
            }
            else if (!event.shiftKey && document.activeElement === lastLink) {
                event.preventDefault();
                focusToggle();
            }
        }

        if (open) {
            document.addEventListener('keydown', handleKeyDown);
        }
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [open, close, drawerToggleRef, focusToggle]);

    const sideDrawerRef = useRef();

    return (
        <div
            ref={sideDrawerRef}
            className={classes.SideDrawer}
        >
            <NavigationItems
                items={routes}
                navItemClicked={close}
                show={open}
            />
        </div>
    );
});

export default SideDrawer;
