import React from 'react';

import ImageSlider from '../../components/UI/ImageSlider/ImageSlider';
import StyledExternalSiteLink from '../../components/UI/StyledExternalSiteLink/StyledExternalSiteLink';

import classes from './Listing.module.css';

const Listing = ({
    listingData,
    fullPageWidth = false
}) => {
    const {
        headlinePart1,
        headlinePart2,
        hostingSiteUrl,
        location,
        dwellingType,
        sqFeet,
        bedrooms,
        sleepQuantity,
        fullBaths,
        halfBaths,
        spaces,
        selectedImagesPaths
    } = listingData;

    const listingImagePaths = [selectedImagesPaths.premiereImage];
    listingImagePaths.push(...selectedImagesPaths.otherImages);

    let listingClasses;
    if (fullPageWidth) {
        listingClasses = [classes.FullPageWidthListing];
    }
    else {
        listingClasses = [classes.Listing];
    }

    return (
        <div className={listingClasses.join(' ')}>
            <ImageSlider imagePaths={listingImagePaths} />
            <div className={classes.BookingLink}>
                <StyledExternalSiteLink
                    destinationUrl={hostingSiteUrl}
                    screenReaderText='Learn More link.  Use this link to open the booking site for this listing in a new browser tab.'
                >
                    Learn More...
                </StyledExternalSiteLink>
            </div>
            <div className={classes.Summary}>
                <div className={classes.Headline}>
                    <b>{headlinePart1}</b>
                    {headlinePart2 &&
                        <b>{headlinePart2}</b>
                    }
                    <i>{location}</i>
                </div>
                <div className={classes.GeneralDwellingInfo}>
                    <b>{dwellingType} - Sleeps {sleepQuantity}</b>
                    {sqFeet && <span>{sqFeet.toLocaleString('en-US')} sq. ft</span>}
                </div>
                <div className={classes.BedsBaths}>
                    {bedrooms && (
                        <span>{bedrooms} {(bedrooms === 1) ? 'bedroom' : 'bedrooms'}</span>
                    )}
                    {fullBaths && (
                        <span>{fullBaths} {(fullBaths === 1) ? 'full bath' : 'full baths'}</span>
                    )}
                    {halfBaths && (
                        <span>{halfBaths} {(halfBaths === 1) ? 'half bath' : 'half baths'}</span>
                    )}
                </div>
                <ul className={classes.Spaces}>
                    {spaces.map((space, index) => {
                        return (
                            <li key={index}>
                                {space}
                                {
                                    (index !== spaces.length - 1)
                                    ? <span className={classes.Bullet}>&#8226;</span>
                                    : null
                                }
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    )
}

export default Listing;
