import React, { forwardRef } from 'react';

import StyledLink from '../../UI/StyledLink/StyledLink';

import classes from './ImageSlide.module.css';

const ImageSlide = forwardRef((
    {
        slideDetails,
        isActive,
        linkFocused,
        linkBlurred
    }, linkRef) => {

    const defaultOverlayTextColor = '#FFF800';
    const defaultOverlayTextShadowColor = '#000799';

    const overlayTextShadowCss = (slideDetails.image.overlayTextShadowColor)
        ? `0px 1px 0 ${slideDetails.image.overlayTextShadowColor}, 0px 2px 0 #000000`
        : `0px 1px 0 ${defaultOverlayTextShadowColor}, 0px 2px 0 #000000`;

    const overlayTextColor = (slideDetails.image.overlayTextColor)
        ? slideDetails.image.overlayTextColor
        : defaultOverlayTextColor;

    return (
        <div className={[classes.ImageSlide, (isActive ? classes.Show : classes.Hide)].join(' ')}>
            <picture className={classes.SlideImage}>
                <source
                    srcSet={`${process.env.PUBLIC_URL}${slideDetails.image.webp}`}
                    alt={slideDetails.image.alt}
                    type="image/webp"
                />
                <source
                    srcSet={`${process.env.PUBLIC_URL}${slideDetails.image.jpg}`}
                    alt={slideDetails.image.alt}
                    type="image/jpeg"
                />
                <img
                    src={`${process.env.PUBLIC_URL}${slideDetails.image.jpg}`}
                    alt={slideDetails.image.alt}
                />
            </picture>
            <div
                className={classes.Caption}
                style={ {
                    color: overlayTextColor,
                    textShadow: overlayTextShadowCss } }
            >
                <b>{slideDetails.captionPart1}</b>
                <b><i>{slideDetails.captionPart2}</i></b>
            </div>
            <StyledLink
                ref={linkRef}
                hash={`#${slideDetails.id}`}
                isFocusable={isActive}
                screenReaderText='Details link.  Use this link to see more details about this listing'
                focused={linkFocused}
                blurred={linkBlurred}
            >
                Details...
            </StyledLink>
        </div>
    );
});

export default ImageSlide;
