import React from 'react';

import LeadText from '../../components/LeadText/LeadText';
import StickySocialLinks from '../../components/StickySocialLinks/StickySocialLinks';
import TeamMember from '../../components/TeamMember/TeamMember';
import StyledLink from '../../components/UI/StyledLink/StyledLink';

import AboutPageData from '../../data/views/about.json';
import TeamMemberData from '../../data/team.json';

import classes from './AboutUs.module.css';

const AboutUs = (props) => {

    return (
        <StickySocialLinks>
            <LeadText
                headingText={AboutPageData.leadText.headline}
                paragraphText={AboutPageData.leadText.paragraph}
            />
            <div className={classes.Content}>
                <div className={classes.MainContent}>
                    <section
                        className={classes.Team}
                        id='our-team'
                    >
                        <h2>
                            Our Team
                        </h2>
                        <div className={classes.TeamMembers}>
                            {
                                Object.keys(TeamMemberData.team).map((member, index) => {
                                    const teamMember = TeamMemberData.team[member];
                                    return (
                                        <TeamMember
                                            key={index}
                                            memberDetails={teamMember}
                                        />
                                    );
                                })
                            }
                        </div>
                    </section>
                    <p className={classes.ServicesSummary}>
                        {AboutPageData.servicesSummary.paragraph}
                    </p>
                    <div className={classes.ServicesLinks}>
                        <StyledLink
                            path='/services/guests'
                            routeState={ {scrollTo: '#services-tabs'} }
                            screenReaderText='Guest Services link.  Use this link to see more details about the services we offer to guests.'
                        >
                            Guest Services
                        </StyledLink>
                        <StyledLink
                            path='/services/homeowners'
                            routeState={ {scrollTo: '#services-tabs'} }
                            screenReaderText='Homeowner Services link.  Use this link to see more details about the services we offer to homeowners.'
                        >
                            Homeowner Services
                        </StyledLink>
                    </div>
                </div>
                <img
                    className={classes.DesktopImage}
                    src={`${process.env.PUBLIC_URL}${AboutPageData.sideImage.imagePath}`}
                    alt={AboutPageData.sideImage.imageAlt}
                />
            </div>
        </StickySocialLinks>
    )
}

export default AboutUs;
