import React from 'react';

import classes from './Backdrop.module.css';

const backdrop = ({
    show,
    clicked,
    positioning = 'fixed'
}) => {

    let backdropClasses = [classes.Backdrop];
    if (positioning === 'fixed') {
        backdropClasses.push(classes.Fixed);
    }
    else if (positioning === 'absolute') {
        backdropClasses.push(classes.Absolute);
    }

    return (
        <>
            {
                show
            ?   <div
                    className={backdropClasses.join(' ')}
                    onClick={clicked}
                >
                </div>
            :   null
        }
        </>
    )

};

export default backdrop;
