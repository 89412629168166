import React, { useState, useRef, forwardRef, useEffect, useContext, useCallback } from 'react';

import ScrollContext from '../../context/ScrollContext';

import Input from '../UI/Input/Input';
import Button from '../../components/UI/Button/Button';
import Spinner from '../../components/Spinner/Spinner';
import AbsolutePositionModal from '../../components/AbsolutePositionModal/AbsolutePositionModal';
import axios from 'axios';
import classes from './ContactUsForm.module.css';

const ContactForm = forwardRef(( {props}, contactFormRefs ) => {
    const { scrollByElementId } = useContext(ScrollContext);
    const phoneNumberInputRef = useRef();

    const [contactForm, setContactForm] = useState({
        name: {
            elementType: 'text',
            label: 'Name',
            spokenLabel: 'Name, please enter your name',
            placeholder: 'Enter Your Name',
            valueType: 'text',
            value: '',
            fieldIconPath: '/icons/user-icon.png',
            fieldIconAlt: 'user icon',
            autoComplete: 'name',
            ref: contactFormRefs.firstInputRef,
            validationRules: [
                {required: true, errorMessage: 'Required'}
            ],
            validationErrorMsg: null
        },
        email: {
            elementType: 'text',
            label: 'Email Address',
            spokenLabel: 'Email Address, please enter your email address',
            placeholder: 'Enter Your Email Address',
            valueType: 'email',
            value: '',
            fieldIconPath: '/icons/email-icon.png',
            fieldIconAlt: 'email icon',
            autoComplete: 'email',
            ref: null,
            validationRules: [
                {required: true, errorMessage: 'Required'},
                {format: 'emailAddress', errorMessage: 'Invalid Entry'}
            ],
            validationErrorMsg: null
        },
        phone: {
            elementType: 'text',
            label: 'Phone Number',
            spokenLabel: 'Phone Number, please enter your phone number',
            placeholder: 'Enter Your Phone Number',
            valueType: 'tel',
            value: '',
            fieldIconPath: '/icons/phone-icon.png',
            fieldIconAlt: 'phone icon',
            autoComplete: 'tel-national',
            ref: phoneNumberInputRef,
            validationRules: [
                {format: 'phoneNumber', errorMessage: 'Invalid Entry'}
            ],
            validationErrorMsg: null
        },
        message: {
            elementType: 'textarea',
            label: 'How Can We Help?',
            spokenLabel: 'How Can We Help? please enter your message',
            placeholder: 'Enter Your Message',
            valueType: 'text',
            value: '',
            fieldIconPath: '/icons/speech-bubble-icon.png',
            fieldIconAlt: 'message icon',
            autoComplete: null,
            ref: null,
            validationRules: [
                {required: true, errorMessage: 'Required'}
            ],
            validationErrorMsg: null
        }
    });

    const [validationErrors, setValidationErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [formSubmissionStatus, setFormSubmissionStatus] = useState(null);

    const clearForm = useCallback(() => {
        const updatedContactForm = {
            ...contactForm
        };
        for (let formElementIdentifier in updatedContactForm) {
            const updatedFormElement = {
                ...updatedContactForm[formElementIdentifier]
            };
            updatedFormElement.value = '';
            updatedFormElement.validationErrorMsg = null;
            updatedContactForm[formElementIdentifier] = updatedFormElement;
        }
        setContactForm(updatedContactForm);
        setValidationErrors([]);
    }, [contactForm]);

    useEffect(() => {
        const handleScroll = () => {
            const contactUsFormContainer = document.querySelector('#contact-us');
            const distanceFromViewportTop = contactUsFormContainer.getBoundingClientRect().top;
            const header = document.querySelector('#site-header');
            const headerHeight = header.offsetHeight;
            if (distanceFromViewportTop > (400 + headerHeight)  || distanceFromViewportTop < (-400 + headerHeight) ) {
                setFormSubmissionStatus(null);
            }
        }

        const handleClick = () => {
            setFormSubmissionStatus(null);
        }

        if (formSubmissionStatus) {
            document.addEventListener('click', handleClick);
            document.addEventListener('scroll', handleScroll);
        }
        else {
            document.removeEventListener('click', handleClick);
            document.removeEventListener('scroll', handleScroll);
        }

        return () => {
            document.removeEventListener('click', handleClick);
            document.removeEventListener('scroll', handleScroll);
        }
    }, [formSubmissionStatus]);

    const inputChangedHandler = (event, inputIdentifier) => {
        const updatedContactForm = {
            ...contactForm
        };
        const updatedFormElement = {
            ...updatedContactForm[inputIdentifier]
        };

        let updatedValue = event.target.value;

        const validationErrorArr = validationErrors;
        if (validationErrorArr.some(error => error.inputId === inputIdentifier)) {
            updatedFormElement.validationErrorMsg = null;
        }

        if (inputIdentifier === 'phone') {
            updatedValue = formatPhoneNumber(updatedValue);
        }

        updatedFormElement.value = updatedValue;
        updatedContactForm[inputIdentifier] = updatedFormElement;

        setContactForm(updatedContactForm);
        setValidationErrors(validationErrorArr);
    };

    const checkValidity = (value, rules) => {
        let errMsg = null;
        if (rules) {
            for (let i = 0; i < rules.length; i++) {
                const rule = rules[i];
                if (rule.required && value === '') {
                    errMsg = rule.errorMessage;
                    break;
                }
                else if (value && rule.format === 'phoneNumber' && !validatePhoneNumber(value)) {
                    errMsg = rule.errorMessage;
                    break;
                }
                else if (rule.format === 'emailAddress' && !validateEmailAddress(value)) {
                    errMsg = rule.errorMessage;
                    break;
                }
            }
        }
        return errMsg;
    }

    const checkInputValidity = (value, inputIdentifier) => {
        const updatedContactForm = {
            ...contactForm
        };
        const updatedFormElement = {
            ...updatedContactForm[inputIdentifier]
        };

        const validationErrorArr = validationErrors;

        const trimmedValue = updatedFormElement.value.trim();
        updatedFormElement.value = trimmedValue;

        // remove any existing error for said input, prior to validating
        const currentInputErrorIndex = validationErrorArr.findIndex(element => element.inputId === inputIdentifier);
        if (currentInputErrorIndex !== -1) {
            validationErrorArr.splice(currentInputErrorIndex, 1);
        }

        const rules = updatedFormElement.validationRules;
        const validationErrorMsg = checkValidity(value, rules);

        if (validationErrorMsg) {
            validationErrorArr.push({inputId: inputIdentifier, errorMessage: validationErrorMsg});
            updatedFormElement.validationErrorMsg = validationErrorMsg;
        }
        else {
            updatedFormElement.validationErrorMsg = null;
        }

        updatedContactForm[inputIdentifier] = updatedFormElement;

        setContactForm(updatedContactForm);
        setValidationErrors(validationErrorArr);
    }

    const checkFormValidity = () => {
        const updatedContactForm = {
            ...contactForm
        };

        const validationErrorArr = validationErrors;

        for (let formElementIdentifier in contactForm) {
            const updatedFormElement = {
                ...updatedContactForm[formElementIdentifier]
            };
            const value = updatedFormElement.value;

            // remove any existing error for the input, prior to validating
            const currentInputErrorIndex = validationErrorArr.findIndex(element => element.inputId === formElementIdentifier);
            if (currentInputErrorIndex !== -1) {
                validationErrorArr.splice(currentInputErrorIndex, 1);
            }

            const rules = updatedFormElement.validationRules;
            const errorMessage = checkValidity(value, rules);

            if (errorMessage) {
                updatedFormElement.validationErrorMsg = errorMessage;
                validationErrorArr.push({inputId: formElementIdentifier, errorMessage: errorMessage});
            }
            else {
                updatedFormElement.validationErrorMsg = null;
            }
            updatedContactForm[formElementIdentifier] = updatedFormElement;
        }
        setContactForm(updatedContactForm);
        setValidationErrors(validationErrorArr);

        if (validationErrors.length !== 0) {
            setFormSubmissionStatus({
                type: 'warning',
                iconPath: `${process.env.PUBLIC_URL}/status icons/warning.png`,
                iconAlt: 'warning',
                message: 'One or more fields have errors.  Please check your entries and try again.'
            });
        }
    }

    const sendMessageHandler = (event) => {
        event.preventDefault();
        scrollByElementId('#contact-us', 0, () => sendMessage());
    }

    const sendMessage = (event) => {
        checkFormValidity();
        if (validationErrors.length === 0) {
            setLoading(true);
            const completedContactForm = {
                ...contactForm
            };
            const emailDetails = {
                'customerDetails': {
                    name: completedContactForm['name'].value,
                    phoneNumber: completedContactForm['phone'].value,
                    emailAddress: completedContactForm['email'].value
                },
                'messageDetails': {
                    subject: 'Customer Message -- AZ Rest and Rec Contact Us Form',
                    message: completedContactForm['message'].value
                }
            };
            axios({
                method: 'POST',
                url: 'https://dmef5hmza2.execute-api.us-west-2.amazonaws.com/v1/contact-us',
                data: emailDetails
            })
            .then( (response) => {
                setLoading(false);
                if (response.status === 204) {
                    setFormSubmissionStatus({
                        type: 'success',
                        iconPath: `${process.env.PUBLIC_URL}/status icons/success.png`,
                        iconAlt: 'success icon',
                        message: 'We received your message and will reach out to you soon!'
                    });
                    clearForm();
                }
                else {
                    setFormSubmissionStatus({
                        type: 'error',
                        iconPath: `${process.env.PUBLIC_URL}/status icons/error.png`,
                        iconAlt: 'error icon',
                        message: 'We\'re sorry, but an error occurred while sending your message.'
                    });
                }
            })
            .catch( (error) => {
                setLoading(false);
                setFormSubmissionStatus({
                    type: 'error',
                    iconPath: `${process.env.PUBLIC_URL}/status icons/error.png`,
                    iconAlt: 'error icon',
                    message: 'We\'re sorry, but an error occurred while sending your message.'
                });
            });
        }
    }

    const clearFormHandler = (event) => {
        event.preventDefault();
        clearForm();
        setFormSubmissionStatus(null);
    }

    const formatPhoneNumber = (value) => {
        value = value.trim();
        value = value.replace(/\D/g,'');
        if (value.length > 3 && value.length <= 6) {
            value = "(" + value.slice(0,3) + ") " + value.slice(3);
        }
        else if (value.length > 6) {
            value = "(" + value.slice(0,3) + ") " + value.slice(3,6) + "-" + value.slice(6);
        }
        return (value.length > 14) ? value.slice(0, -1) : value;
    }

    const validatePhoneNumber = (value) => {
        // var phoneRe = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/;
        var phoneRe = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{4}$/;
        var digits = value.replace(/\D/g, "");
        return phoneRe.test(digits);
    }

    const validateEmailAddress = (value) => {
        // const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(value.toLowerCase());
    }

    const formElementsArray = [];
    for (let key in contactForm) {
        const inputElementDetails = contactForm[key];

        formElementsArray.push({
            id: key,
            label: inputElementDetails.label,
            config: inputElementDetails,
            validationErrorMsg: inputElementDetails.validationErrorMsg
        });
    }

    let formSubmissionStatusClasses = [classes.FormSubmissionStatus];

    if (formSubmissionStatus && formSubmissionStatus.type === 'warning') {
        formSubmissionStatusClasses.push(classes.WarningStatus);
    }
    else if (formSubmissionStatus && formSubmissionStatus.type === 'error') {
        formSubmissionStatusClasses.push(classes.ErrorStatus);
    }
    else if (formSubmissionStatus && formSubmissionStatus.type === 'success') {
        formSubmissionStatusClasses.push(classes.SuccessStatus);
    }

    return (
        <div
            style={{backgroundImage: `url(${process.env.PUBLIC_URL + '/other/road-to-monument-valley.jpg'})`}}
            className={classes.ContactUsForm}
            // ref={contactFormRefs.contactFormRef}
            id='contact-us'
        >
            {loading
                ?   <AbsolutePositionModal clicked={() => {} }>
                        <Spinner />
                    </AbsolutePositionModal>
                :   null
            }
            <span
                id='formDescription'
                className={classes.FormDescription}
            >
                Send us a message using the form below.<br></br>
                We'll get back to you shortly!
            </span>
            <div className={classes.FormSubmissionStatusWrapper}>
                {
                    formSubmissionStatus
                    ? (
                        <div className={formSubmissionStatusClasses.join(' ')}>
                            <img
                                src={formSubmissionStatus.iconPath}
                                alt={formSubmissionStatus.iconAlt}
                            />
                            <b>
                                {formSubmissionStatus.message}
                            </b>
                        </div>
                    )
                    : null
                }
            </div>
            <form
                onSubmit={sendMessageHandler}
                aria-label='Contact Us Form.  We would love to hear from you!  Use this form to send us a message, and we will get back to you shortly'
                aria-describedby='formDescription'
                tabIndex='0'
            >
                <div
                    className={classes.Inputs}
                    aria-label='Contact Us Form'
                >
                {
                    Object.keys(contactForm).map((inputKey) => {
                        const formInput = contactForm[inputKey];
                        return <Input
                            key={inputKey}
                            id={inputKey}
                            name={inputKey}
                            label={formInput.label}
                            screenReaderText={formInput.spokenLabel}
                            elementType={formInput.elementType}
                            elementConfig={{
                                type: formInput.valueType,
                                placeholder: formInput.placeholder,
                                value: formInput.value,
                                autoComplete: formInput.autoComplete,
                                iconpath: formInput.fieldIconPath
                            }}
                            validationRules={formInput.validationRules}
                            errMessage={formInput.validationErrorMsg}
                            inputRef={formInput.ref}
                            changed={(event) => inputChangedHandler(event, inputKey)}
                            blurred={(event) => checkInputValidity(event.target.value, inputKey)}
                        />
                    })
                }
                </div>
                <div className={classes.Buttons}>
                    <Button
                        types={['contained', 'large']}
                        clicked={sendMessageHandler}
                        screenReaderText='Submit button.  Use this button to send us the contact info and message you entered on our contact form.'
                    >
                        Send Message
                    </Button>
                    <Button
                        types={['contained', 'large']}
                        clicked={clearFormHandler}
                        screenReaderText='Clear button.  Use this button to clear the information you entered on our contact form.'
                    >
                        Clear Form
                    </Button>
                </div>
            </form>
        </div>
    )
})

export default ContactForm;
