import React from 'react';

import Hero from '../../components/Hero/Hero';
import LeadText from '../../components/LeadText/LeadText';
import StickySocialLinks from '../../components/StickySocialLinks/StickySocialLinks';
import Listing from '../../components/Listing/Listing';
import StyledLink from '../../components/UI/StyledLink/StyledLink';
import Headshot from '../../components/Headshot/Headshot';

import HomePageData from '../../data/views/home.json';
import ListingData from '../../data/listings.json';
import TeamMemberData from '../../data/team.json';

import classes from './Home.module.css';

const Home = (props) => {

    const featuredListingDetails = ListingData["featuredListing"];

    return (
        <>
            <Hero
                imageDesktop={`${process.env.PUBLIC_URL}/other/arizona-landscape.jpg`}
                imageTablet={`${process.env.PUBLIC_URL}/other/arizona-landscape.jpg`}
                imageCell={`${process.env.PUBLIC_URL}/other/arizona-landscape.jpg`}
                headlinePart1='Welcome to'
                headlinePart2='Arizona Rest and Recreation'
                headlineColor='#FFF800'
                headlineShadowColor='#000000'
            />
            <StickySocialLinks>
                <LeadText
                    headingText={HomePageData.leadText.headline}
                    paragraphText={HomePageData.leadText.paragraph}
                />
                <h2 className={classes.HomeH2}>Featured Listing</h2>
                <Listing
                    listingData={featuredListingDetails}
                    fullPageWidth={true}
                />
                <StyledLink
                    path='/listings'
                    hash='all-listings'
                    screenReaderText='All Listings link.  Use this link to see all of our listings.'
                >
                    See All Listings...
                </StyledLink>
                <div className={classes.TeamAndServices}>
                    <div className={classes.Headshots}>
                        {
                            Object.keys(TeamMemberData.team).map((member, memberIndex) => {
                                const teamMember = TeamMemberData.team[member];
                                return (
                                    <Headshot
                                        key={memberIndex}
                                        name={`${teamMember.firstName} ${teamMember.lastName}`}
                                        title={teamMember.title}
                                        imagePath={teamMember.headshotImagePath}
                                        imageAlt={teamMember.headshotImageAlt}
                                    />
                                );
                            })
                        }
                    </div>
                    <div className={classes.Summary}>
                        <h2 className={classes.SummaryHeadline}>Why Getaways Are Better with Us</h2>
                        <p>
                            {
                                HomePageData.teamSummary.paragraph
                            }
                        </p>
                        <div className={classes.LinkContainer}>
                            <StyledLink
                                path='/about-us'
                                hash='our-team'
                                screenReaderText='Meet Our Team link.  Use this link to go to the About Us page and learn more about our team.'
                            >
                                Meet Our Team
                            </StyledLink>
                        </div>
                        <h2 className={classes.SummaryHeadline}>Specialized Services for Guests and Homeowners</h2>
                        <p>
                            {
                                HomePageData.servicesSummary.paragraph
                            }
                        </p>
                        <div className={classes.LinkContainer}>
                            <StyledLink
                                path='/services/guests'
                                routeState={ {scrollTo: '#services-tabs'} }
                                screenReaderText='Guest Services link.  Use this link to see more details about the services we offer to guests.'
                            >
                                Why Book with Us?
                            </StyledLink>
                            <StyledLink
                                path='/services/homeowners'
                                routeState={ {scrollTo: '#services-tabs'} }
                                screenReaderText='Homeowner Services link.  Use this link to see more details about the services we offer to homeowners.'
                            >
                                Why List with Us?
                            </StyledLink>
                        </div>
                    </div>
                </div>
            </StickySocialLinks>
        </>
    )
}

export default Home;
