import React from 'react';

import classes from './Nested404.module.css';

function Nested404({
    notFoundImgPath,
    notFoundImgAlt
}) {

    return (
        <div
            className={classes.Nested404}
        >
            <b>404 - Resource Not Found</b>
            <img src={notFoundImgPath} alt={notFoundImgAlt} />
        </div>
    )
}

export default Nested404;
