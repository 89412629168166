import React from 'react';

import commonClasses from '../../../styles/common.module.css';
import classes from './StyledExternalSiteLink.module.css';

const StyledExternalSiteLink = ({
    destinationUrl,
    openInNewTab = true,
    screenReaderText,
    children
}) => {
    const targetValue = openInNewTab ? '_blank' : '_self';
    const relValue = openInNewTab ? 'noopener noreferrer' : null;
    
    return (
        <a
            className={[commonClasses.Clickable, classes.StyledExternalSiteLink].join(' ')}
            href={destinationUrl}
            target={targetValue}
            rel={relValue}
            aria-label={screenReaderText}
        >
            {children}
        </a>
    );
}

export default StyledExternalSiteLink;
