import React from 'react';

import classes from './Headshot.module.css';

const Headshot = ({
    imagePath,
    imageAlt,
    name,
    title
}) => {
    return (
        <div>
            <img
                className={classes.HeadshotImage}
                src={`${process.env.PUBLIC_URL}${imagePath}`}
                alt={imageAlt}
            />
            <b>{name}</b>
            <i>{title}</i>
        </div>
    )
}

export default Headshot;
