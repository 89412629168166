import React, { useState, useRef } from 'react';

import Header from '../Header/Header';
import SideDrawer from '../SideDrawer/SideDrawer';
import Backdrop from '../UI/Backdrop/Backdrop';
import ContactUsForm from '../ContactUsForm/ContactUsForm';
import Footer from '../Footer/Footer';

import LocationSensor from '../LocationSensor/LocationSensor';

import ScrollContext from '../../context/ScrollContext';

import RouteConfig from '../../data/routes.json';

import useDeclarativeScrollAndFocus from '../../hooks/useDeclarativeScrollAndFocus';

import classes from './Layout.module.css';

const Layout = (props) => {
    const {scrollByElementId} = useDeclarativeScrollAndFocus();

    const drawerToggleRef = useRef();
    const contactFormRef = useRef();
    const firstInputRef = useRef();

    const [showMobileNav, setShowMobileNav] = useState(false);

    const contactFormRefs = {
        contactFormRef: contactFormRef,
        firstInputRef: firstInputRef
    }

    const toggleShowMobileNav = () => {
        setShowMobileNav((showMobileNav) => !showMobileNav);
    }

    const closeSideDrawer = () => {
        setShowMobileNav(false);
        drawerToggleRef.current.focus();
    }

    const closeSideDrawerMaintainFocus = () => {
        setShowMobileNav(false);
    }

    const focusDrawerToggle = () => {
        drawerToggleRef.current.focus();
    }

    const viewClasses = [classes.View];
    if (showMobileNav) {
        viewClasses.push(classes.MoveLeft);
    }

    function checkNotHomeRoute(total, route) {
        if (route.path !== '/') {
            total = total + 1;
        }
      return total;
    }

    const sidedrawerLinksLength = RouteConfig.mainRoutes.reduce(checkNotHomeRoute, 0);

    const hideDelayIncrementInMillis = 50;
    let transitionDelayInMillis;
    if (showMobileNav) {
        transitionDelayInMillis = '0ms';
    }
    else {
        transitionDelayInMillis = `${200 + sidedrawerLinksLength * hideDelayIncrementInMillis}ms`;
    }

    return (
        <ScrollContext.Provider value={{scrollByElementId}}>
            <LocationSensor />
            <Header
                ref={drawerToggleRef}
                routes={RouteConfig.mainRoutes}
                drawerToggleClicked={toggleShowMobileNav}
                logoClicked={closeSideDrawerMaintainFocus}
                sideDrawerOpen={showMobileNav}
            />
            <SideDrawer
                ref={drawerToggleRef}
                routes={RouteConfig.mainRoutes}
                open={showMobileNav}
                close={closeSideDrawer}
                focusToggle={focusDrawerToggle}
            />
            <div
                className={viewClasses.join(' ')}
                style={ {transitionDelay: transitionDelayInMillis} }
            >
                <Backdrop
                    show={showMobileNav}
                    clicked={closeSideDrawer}
                    positioning='absolute'
                />
                <main>
                    {props.children}
                </main>
                <ContactUsForm ref={contactFormRefs} />
                <Footer routes={RouteConfig} />
            </div>
        </ScrollContext.Provider>
    )
}

export default Layout;
